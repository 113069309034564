import useRouter from '@finst/core/src/scripts/components/router/hooks/use-router';
import useAsync from '@finst/core/src/scripts/hooks/use-async';
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import remoteLogger from '@finst/core/src/scripts/loggers/remote-logger';
import { useEffect, useState } from 'preact/compat';
import { defaultAccount } from '../../../models/account';
import canSaveAccountLocally from '../../../services/identity/can-save-account-locally';
import deleteLocalAccount from '../../../services/identity/delete-local-account';
import getEmailVerificationUrlParams from '../../../services/identity/get-email-verification-url-params';
import getIdentity from '../../../services/identity/get-identity';
import getIdentityStateAccount from '../../../services/identity/get-identity-state-account';
import getLocalAccounts from '../../../services/identity/get-local-accounts';
import saveLocalAccount from '../../../services/identity/save-local-account';
function upsertAccount(accounts, updatedAccount) {
    const { email, firstName, lastName } = updatedAccount;
    if (!email) {
        return accounts;
    }
    return [
        {
            email,
            firstName,
            lastName
        },
        ...accounts.filter((account)=>account.email !== email)
    ];
}
export default function useIdentityContextLoader(secureMessageBroker) {
    const { location } = useRouter();
    const [contextValue, setContextValue] = useState(()=>{
        const emailVerificationUrlParams = getEmailVerificationUrlParams(location.search);
        return {
            ...location.pathname === '/identity/login/email-verification' && emailVerificationUrlParams ? {
                ...emailVerificationUrlParams,
                state: 'WAIT_LOGIN_BY_LINK'
            } : {
                state: 'UNAUTHORIZED'
            },
            account: defaultAccount,
            accounts: [],
            removeAccount: (email)=>{
                deleteLocalAccount(email).catch(localLogger.error);
                setContextValue((contextValue)=>({
                        ...contextValue,
                        accounts: contextValue.accounts.filter((account)=>account.email !== email)
                    }));
            },
            update: (data)=>setContextValue((contextValue)=>({
                        ...data,
                        accounts: upsertAccount(contextValue.accounts, data.account),
                        removeAccount: contextValue.removeAccount,
                        update: contextValue.update
                    }))
        };
    });
    const isLocalAccountSupported = canSaveAccountLocally();
    const { isLoading: isLocalAccountLoading } = useAsync(async ()=>{
        const localAccounts = isLocalAccountSupported ? await getLocalAccounts() : [];
        setContextValue((contextValue)=>{
            const currentAccount = contextValue.account;
            const localAccount = currentAccount === defaultAccount ? localAccounts[0] : localAccounts.find((account)=>account.email === currentAccount.email);
            const updatedAccount = localAccount ? currentAccount === defaultAccount ? {
                ...defaultAccount,
                ...localAccount
            } : {
                ...defaultAccount,
                ...localAccount,
                ...currentAccount
            } : currentAccount;
            return {
                ...contextValue,
                account: updatedAccount,
                accounts: upsertAccount(localAccounts, updatedAccount)
            };
        });
    }, [
        isLocalAccountSupported
    ]);
    const { isLoading: isIdentityLoading } = useAsync(async ()=>{
        const stateInfo = await getIdentity();
        const identityStateAccount = getIdentityStateAccount(stateInfo);
        setContextValue((contextValue)=>{
            // If we confirm email link, we should ignore identity state from `getIdentity()` as it's based on the
            // active session cookie that might be related to a different account.
            if (contextValue.state === 'WAIT_LOGIN_BY_LINK') {
                return contextValue;
            }
            const updatedAccount = identityStateAccount ? {
                ...contextValue.account,
                ...identityStateAccount
            } : contextValue.account;
            return {
                ...stateInfo,
                account: updatedAccount,
                accounts: upsertAccount(contextValue.accounts, updatedAccount),
                removeAccount: contextValue.removeAccount,
                update: contextValue.update
            };
        });
    }, []);
    const { account: { id: accountId, email, firstName, lastName }, state } = contextValue;
    useEffect(()=>{
        return secureMessageBroker === null || secureMessageBroker === void 0 ? void 0 : secureMessageBroker.on({
            action: 'verificationPaymentComplete',
            onError: remoteLogger.error,
            onSuccess (stateInfo) {
                const identityStateAccount = getIdentityStateAccount(stateInfo);
                setContextValue((contextValue)=>{
                    const updatedAccount = identityStateAccount ? {
                        ...contextValue.account,
                        ...identityStateAccount
                    } : contextValue.account;
                    return {
                        ...stateInfo,
                        account: updatedAccount,
                        accounts: upsertAccount(contextValue.accounts, updatedAccount),
                        removeAccount: contextValue.removeAccount,
                        update: contextValue.update
                    };
                });
            }
        });
    }, [
        secureMessageBroker
    ]);
    useEffect(()=>{
        if (isLocalAccountSupported && accountId && email && state !== 'UNAUTHORIZED' && state !== 'WAIT_LOGIN_BY_LINK' && state !== 'WAIT_PIN_SETUP' && state !== 'TWO_FA_VERIFICATION') {
            // save account data locally in the app when account is logged in and verified
            saveLocalAccount({
                email,
                firstName,
                lastName
            }).catch(localLogger.error);
        }
    }, [
        isLocalAccountSupported,
        state,
        accountId,
        email,
        firstName,
        lastName
    ]);
    return {
        isLoading: isLocalAccountLoading || isIdentityLoading,
        contextValue
    };
}

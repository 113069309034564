import isWebViewApp from './is-web-view-app';
import openInAppBrowser from './open-in-app-browser';
function isNavLink(target) {
    return target != null && target.tagName === 'A';
}
/**
 * @description Open external links in the system's web browser from WebView app.
 * @param {Event} event
 * @returns {void}
 */ export default function handleExternalLinkClick(event) {
    const link = isNavLink(event.target) ? event.target : isNavLink(event.currentTarget) ? event.currentTarget : undefined;
    const urlAttribute = link === null || link === void 0 ? void 0 : link.getAttribute('href');
    // Do not open relative app links in the system's web browser if the app is running in WebView,
    // unless target="_blank" is intentionally specified
    if (link && urlAttribute && (urlAttribute[0] !== '/' || link.target === '_blank') && isWebViewApp()) {
        event.preventDefault();
        openInAppBrowser({
            url: link.href,
            target: '_system'
        });
    }
}

import request from '@finst/core/src/scripts/services/api/request';
import asyncMemo from '@finst/core/src/scripts/utils/async/async-memo';
import getStakingAssetsInfo from '../staking/get-staking-assets-info';
import getStakingBundleInfo from '../staking/get-staking-bundle-info';
export default async function getAllBundles() {
    // keep ALL bundles in memory on FE
    return asyncMemo('all-bundles', async ()=>{
        const [bundleResponses, stakingAssetsInfoResponse] = await Promise.all([
            request({
                url: '/api/bundles/all'
            }),
            getStakingAssetsInfo()
        ]);
        const stakingAssetsInfo = Object.fromEntries(stakingAssetsInfoResponse.map((item)=>[
                item.asset.symbol,
                item
            ]));
        return bundleResponses.map((bundle)=>({
                ...bundle,
                stakingInterest: getStakingBundleInfo(bundle, stakingAssetsInfo).interest
            }));
    });
}

import { AppError } from '../../models/app-error';
export default function parseApiErrorResponse(response, responseBody) {
    var _responseBody_errors;
    if (responseBody === null || responseBody === void 0 ? void 0 : (_responseBody_errors = responseBody.errors) === null || _responseBody_errors === void 0 ? void 0 : _responseBody_errors.length) {
        return new AppError(responseBody);
    }
    if (response.status === 401) {
        return new AppError({
            code: 'Unauthorized'
        });
    }
    if (response.status === 403) {
        return new AppError({
            code: 'Forbidden'
        });
    }
    if (response.status === 429) {
        return new AppError({
            code: 'RequestLimitExceeded'
        });
    }
    return new AppError();
}

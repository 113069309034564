import { _ as _define_property } from "@swc/helpers/_/_define_property";
import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import unsubscribeAll from '@finst/core/src/scripts/utils/subscription/unsubscribe-all';
import getFieldValues from './get-field-values';
import getMarketData from './get-market-data';
import getMarketsMovers from './get-markets-movers';
import getMostTradedAssets from './get-most-traded-assets';
class Quotecast {
    onUpdate(keys, fields, callback) {
        const { assetSubscriptions, data } = this;
        const subscription = {
            fields,
            callback
        };
        let initialUpdate;
        keys.forEach((subscriptionKey)=>{
            let subscriptions = assetSubscriptions.get(subscriptionKey);
            if (!subscriptions) {
                subscriptions = new Set();
                assetSubscriptions.set(subscriptionKey, subscriptions);
            }
            subscriptions.add(subscription);
            const storedValues = data[subscriptionKey];
            const fieldValues = storedValues && getFieldValues(storedValues, fields);
            if (fieldValues) {
                initialUpdate = initialUpdate || {};
                initialUpdate[subscriptionKey] = fieldValues;
            }
        });
        if (initialUpdate) {
            callback(initialUpdate);
        }
        return ()=>{
            keys.forEach((subscriptionKey)=>{
                const subscriptions = assetSubscriptions.get(subscriptionKey);
                if (!subscriptions) {
                    return;
                }
                const wasDeleted = subscriptions.delete(subscription);
                // it was the last subscription
                if (wasDeleted && !subscriptions.size) {
                    assetSubscriptions.delete(subscriptionKey);
                }
            });
        };
    }
    onMarketMovers(callback) {
        const { marketMoverSubscriptions, data } = this;
        const symbols = getMarketsMovers(data);
        marketMoverSubscriptions.add(callback);
        if (symbols[0] !== undefined) {
            callback(symbols);
        }
        return ()=>{
            marketMoverSubscriptions.delete(callback);
        };
    }
    onMostTradedAssets(callback) {
        const { mostTradedAssetSubscriptions, data } = this;
        const symbols = getMostTradedAssets(data);
        mostTradedAssetSubscriptions.add(callback);
        if (symbols[0] !== undefined) {
            callback(symbols);
        }
        return ()=>{
            mostTradedAssetSubscriptions.delete(callback);
        };
    }
    onMarketData(callback) {
        const { marketDataSubscriptions, data } = this;
        const marketData = getMarketData(data);
        marketDataSubscriptions.add(callback);
        if (marketData.relativeDifference !== undefined) {
            callback(marketData);
        }
        return ()=>{
            marketDataSubscriptions.delete(callback);
        };
    }
    close() {
        unsubscribeAll(this.unsubscribes);
        this.assetSubscriptions.clear();
        this.marketMoverSubscriptions.clear();
        this.marketDataSubscriptions.clear();
        this.mostTradedAssetSubscriptions.clear();
    }
    constructor(props){
        _define_property(this, "assetSubscriptions", new Map());
        _define_property(this, "marketMoverSubscriptions", new Set());
        _define_property(this, "marketDataSubscriptions", new Set());
        _define_property(this, "mostTradedAssetSubscriptions", new Set());
        _define_property(this, "data", {});
        _define_property(this, "unsubscribes", void 0);
        _define_property(this, "onSnapshot", (update)=>{
            const { assetSubscriptions, marketMoverSubscriptions, marketDataSubscriptions, mostTradedAssetSubscriptions, data } = this;
            const pendingUpdates = new Map();
            Object.entries(update).forEach(([key, updatedValues])=>{
                const subscriptions = assetSubscriptions.get(key);
                subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.forEach(({ fields, callback })=>{
                    const fieldValues = getFieldValues(updatedValues, fields);
                    if (fieldValues) {
                        let update = pendingUpdates.get(callback);
                        if (!update) {
                            update = {};
                            pendingUpdates.set(callback, update);
                        }
                        update[key] = {
                            ...update[key],
                            ...fieldValues
                        };
                    }
                });
                data[key] = {
                    ...data[key],
                    ...updatedValues
                };
            });
            pendingUpdates.forEach((update, callback)=>callback(update));
            pendingUpdates.clear();
            if (marketMoverSubscriptions.size) {
                const symbols = getMarketsMovers(data);
                marketMoverSubscriptions.forEach((callback)=>callback(symbols));
            }
            if (mostTradedAssetSubscriptions.size) {
                const symbols = getMostTradedAssets(data);
                mostTradedAssetSubscriptions.forEach((callback)=>callback(symbols));
            }
            if (marketDataSubscriptions.size) {
                const marketData = getMarketData(data);
                marketDataSubscriptions.forEach((callback)=>callback(marketData));
            }
        });
        const { messageBroker } = props;
        this.unsubscribes = [
            messageBroker.on({
                action: 'quotecastUpdate',
                onSuccess: this.onSnapshot,
                onError: localLogger.error
            }),
            // request a snapshot again when a new connection established (e.g. after reconnect)
            messageBroker.onConnect(()=>messageBroker.send({
                    action: 'quotecastSubscribe',
                    data: {},
                    onError: localLogger.error
                }))
        ];
    }
}
export { Quotecast as default };

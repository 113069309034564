const cache = {};
// Do not return `undefined` as a result of the memoization as it means the value has not been loaded yet
export default async function asyncMemo(cacheKey, load) {
    let cachedResult = cache[cacheKey];
    if (!cachedResult) {
        cachedResult = await load();
        cache[cacheKey] = cachedResult;
    }
    return cachedResult;
}

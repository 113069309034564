const darkThemeColors = {
    '--white-color': '#FFFFFF',
    '--black-color': '#121212',
    '--grey500-color': '#BDBDBD',
    '--grey400-color': '#D6D6D6',
    '--positive500-color': '#008C99',
    '--positive200-color': '#C7F4F5',
    '--negative500-color': '#EC4665',
    '--negative200-color': '#FEDCE3',
    '--supporting500-color': '#6B8AE6',
    '--supporting200-color': '#CAD8F7',
    '--warning500-color': '#FFAF0F',
    '--warning200-color': '#FFE2A3',
    '--default-fill-color': '#121212',
    '--default-text-color': '#FFFFFF',
    '--strong-fill-color': '#808080',
    '--medium-fill-color': '#383838',
    '--mild-fill-color': '#212121',
    '--mild-text-color': '#BDBDBD',
    '--negative-text-color': '#EC4665',
    '--negative-fill-color': '#EC4665',
    '--positive-text-color': '#33C4CC',
    '--positive-fill-color': '#008C99',
    '--default-border-color': '#5C5C5C',
    '--mild-border-color': '#383838',
    '--default-button-fill-color': '#383838',
    '--default-button-text-color': '#FFFFFF',
    '--primary-button-fill-color': '#FFFFFF',
    '--primary-button-text-color': '#121212',
    '--chart-positive-fill-color': '#063541',
    '--chart-negative-fill-color': '#341416'
};
const lightThemeColors = {
    '--white-color': '#FFFFFF',
    '--black-color': '#121212',
    '--grey500-color': '#BDBDBD',
    '--grey400-color': '#D6D6D6',
    '--positive500-color': '#008C99',
    '--positive200-color': '#C7F4F5',
    '--negative500-color': '#EC4665',
    '--negative200-color': '#FEDCE3',
    '--supporting500-color': '#6B8AE6',
    '--supporting200-color': '#CAD8F7',
    '--warning500-color': '#FFAF0F',
    '--warning200-color': '#FFE2A3',
    '--default-fill-color': '#FFFFFF',
    '--default-text-color': '#121212',
    '--strong-fill-color': '#BDBDBD',
    '--medium-fill-color': '#EDEDED',
    '--mild-fill-color': '#F7F7F7',
    '--mild-text-color': '#808080',
    '--negative-text-color': '#EC4665',
    '--negative-fill-color': '#EC4665',
    '--positive-text-color': '#007E8A',
    '--positive-fill-color': '#008C99',
    '--default-border-color': '#D6D6D6',
    '--mild-border-color': '#E3E3E3',
    '--default-button-fill-color': '#EDEDED',
    '--default-button-text-color': '#121212',
    '--primary-button-fill-color': '#121212',
    '--primary-button-text-color': '#FFFFFF',
    '--chart-positive-fill-color': '#E5FAFA',
    '--chart-negative-fill-color': '#FFF0F2'
};
/**
 * @description Should export the same values as in CSS file.
 *  We have to use HEX notation here as most of the JS libraries (charts, Cordova plugins) accept only this format.
 * @see {@link ../../../styles/variables.module.css}
 * @param {Theme} theme
 * @returns {Colors}
 */ export default function getColors(theme) {
    return theme === 'dark' ? darkThemeColors : lightThemeColors;
}

import padNumber from '../number/pad-number';
import isEqualDate from './is-equal-date';
import isValidDate from './is-valid-date';
const formattingTokensPattern = /(\[[^[]*])|(\\)?(ss|mm|HH|YYYY|MM|DD|.)/g;
const formatters = {
    // Month: 01, 02, ..., 12
    MM: (date)=>padNumber(date.getMonth() + 1),
    // Day of month: 01, 02, ..., 31
    DD: (date)=>padNumber(date.getDate()),
    // Year: 1900, 1901, ..., 2099
    YYYY: (date)=>String(date.getFullYear()),
    // Hour: 00, 01, ..., 23
    HH: (date)=>padNumber(date.getHours()),
    // Minute: 00, 01, ..., 59
    mm: (date)=>padNumber(date.getMinutes()),
    // Second: 00, 01, ..., 59
    ss: (date)=>padNumber(date.getSeconds())
};
/**
 * @description Date formatter. Supported tokens:
 *  MM   - 01, 02, ..., 12
 *  DD   - 01, 02, ..., 31
 *  YYYY - 1900, 1901, ..., 2099
 *  HH   - 00, 01, ... 23
 *  mm   - 00, 01, ..., 59
 *  ss   - 00, 01, ..., 59
 * @param {Date} date
 * @param {string} formatString
 * @param {DateFormattingOptions} [options]
 * @returns {string}
 */ export default function formatDate(date, formatString, options) {
    if (!formatString || !isValidDate(date)) {
        return formatString;
    }
    if ((options === null || options === void 0 ? void 0 : options.todayFormat) && isEqualDate(date, new Date())) {
        formatString = options.todayFormat;
    }
    return formatString.replace(formattingTokensPattern, (token)=>{
        const formatter = formatters[token];
        return formatter ? formatter(date) : token;
    });
}

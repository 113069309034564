import localLogger from '../../loggers/local-logger';
import wait from '../async/wait';
const handleChunkLoadingError = (error)=>{
    var _document_head_querySelector;
    localLogger.error(error);
    if (handleChunkLoadingError.disabled) {
        return;
    }
    // do not handle more than 1 error
    handleChunkLoadingError.disabled = true;
    const manifestUrl = (_document_head_querySelector = document.head.querySelector('link[rel="manifest"]')) === null || _document_head_querySelector === void 0 ? void 0 : _document_head_querySelector.href;
    if (!manifestUrl) {
        return;
    }
    // add a timestamp to load the latest actual version of Manifest from the server
    fetch(`${manifestUrl}?t=${Date.now()}`).then((response)=>response.json()).then(({ app_version: availableAppVersion })=>{
        var _navigator_serviceWorker;
        if (!availableAppVersion || availableAppVersion === Globals.APP_VERSION) {
            throw new Error(`App version did not change: ${Globals.APP_VERSION}`);
        }
        return Promise.race([
            (_navigator_serviceWorker = navigator.serviceWorker) === null || _navigator_serviceWorker === void 0 ? void 0 : _navigator_serviceWorker.ready,
            // we need a timeout (2 seconds) because `.ready` promise might be indefinite
            wait(2000)
        ])// unregister active Service Worker
        .then((registration)=>registration === null || registration === void 0 ? void 0 : registration.unregister())// log error but continue a process
        .catch(localLogger.error).then(()=>availableAppVersion);
    }).then((availableAppVersion)=>{
        const url = new URL(location.href);
        url.searchParams.append('reloadAppVersion', availableAppVersion);
        // reload a page from server
        location.replace(url.toString());
    }).catch(localLogger.error);
};
// disable in dev-server
handleChunkLoadingError.disabled = !Globals.PROD_BUILD;
export default handleChunkLoadingError;

export default function calculateTotalPortfolio(update) {
    const { availableBalance, balance, assets, absoluteTotalPl, absoluteDailyPl } = update;
    const totalWithoutReservations = assets + availableBalance;
    return {
        total: assets + balance,
        assets,
        balance,
        availableBalance,
        absoluteTotalPl,
        absoluteDailyPl,
        relativeDailyPl: absoluteDailyPl && totalWithoutReservations && absoluteDailyPl * 100 / (totalWithoutReservations - absoluteDailyPl)
    };
}
